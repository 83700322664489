import React from 'react';
import logo from './logo.svg';
import './App.css';
import NonUser from './views/NonUser/NonUser';
import User from './views/User/User';

class App extends React.Component {
  // TODO might switch user from state to server
  state = {
    user: false
  }
  componentDidMount() {
    function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    const cookie = getCookie('push_access_key')
    console.log(cookie)
    this.setState({ user: cookie ? true : false })
  }
  render() {
    return (
      this.state.user ? <User /> : <NonUser />
    );
  }

}

export default App;
