import React, { useEffect, useState } from 'react'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import { makeStyles } from "@material-ui/core/styles";
import CardAvatar from '../../components/Card/CardAvatar'
import CardBody from '../../components/Card/CardBody'
import CardFooter from '../../components/Card/CardFooter'
import CustomInput from '../../components/CustomInput/CustomInput'
import { Button } from '@material-ui/core'
import InputLabel from "@material-ui/core/InputLabel";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    img: {
        width: "130px",
        height: "130px",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        borderRadius: "50%",
        objectFit: "cover",
    },
    cardTitle: {
        fontWeight: 100
    },
    description: {
        fontSize: "18px"
    }
};

const useStyles = makeStyles(styles);

export default function Profile(props) {
    const classes = useStyles();
    const [user, setUser] = useState({})
    const [error, setError] = useState('')
    const [company, setCompany] = useState('')
    const [role, setRole] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [blurb, setBlurb] = useState('')

    const handleCompany = event => setCompany(event.target.value)
    const handleUsername = event => setUsername(event.target.value)
    const handleEmail = event => setEmail(event.target.value)
    const handleFirst = event => setFirst(event.target.value)
    const handleLast = event => setLast(event.target.value)
    const handleBlurb = event => setBlurb(event.target.value)

    useEffect(() => {
        const sampleUser = {
            role: "Admin",
            first_name: "Mike",
            last_name: "Bubly",
            company: "PushPros",
            username: "prisonmichael",
            email: 'prisonmichael@rikers.gov',
            blurb: 'I like to keep things bubly',

        }
        if (sampleUser) {
            setRole(sampleUser.role)
            setCompany(sampleUser.company)
            setUsername(sampleUser.username)
            setEmail(sampleUser.email)
            setFirst(sampleUser.first_name)
            setLast(sampleUser.last_name)
            setBlurb(sampleUser.blurb)
        }
    }, [])
    const sampleUser = {
        role: "Admin",
        first_name: "Mike",
        last_name: "Bubly",
        company: "PushPros",
        username: "prisonmichael",
        email: 'prisonmichael@rikers.gov',
        blurb: 'I like to keep things bubly',

    }
    let { view } = props
    useEffect(() => {
        if (username && view !== 'my-profile') {
            async function fetchData() {
                const res = await fetch("https://api.pushnetwork.com:3030/data")
                res
                    .json()
                    .then(res => setUser(res.data))
                    .catch(err => setError(err))
            }
            fetchData()
        }
    }, [])
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
                        <p className={classes.cardCategoryWhite}>Complete your profile</p>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                                <CustomInput
                                    labelText={company ? company : "Company"}
                                    id="company-disabled"
                                    value={company}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true
                                    }} />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText={"Username"}
                                    id="username"
                                    value={username}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    handle={handleUsername}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="Email address"
                                    value={email}
                                    id="email-address"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    handle={handleEmail}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="First Name"
                                    id="first-name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={first}
                                    handle={handleFirst}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Last Name"
                                    id="last-name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={last}
                                    handle={handleLast}
                                />
                            </GridItem>
                        </GridContainer>
                        {/* <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="City"
                                    id="city"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="Country"
                                    id="country"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="Postal Code"
                                    id="postal-code"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer> */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                                <CustomInput
                                    labelText="Blurb"
                                    id="about-me"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 5
                                    }}
                                    value={blurb}
                                    handle={handleBlurb}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary">Update Profile</Button>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Card profile>
                    <CardAvatar>
                        <img className={classes.img} src='https://cdn.pixabay.com/photo/2015/03/03/20/42/man-657869_1280.jpg' alt="..." />
                    </CardAvatar>
                    <CardBody>
                        <h2 className={classes.cardCategory}>{sampleUser.role}</h2>
                        <h1 className={classes.cardTitle}>{first} {last}</h1>
                        <p className={classes.description}>
                            {blurb}
                        </p>
                        <Button color="primary" round>
                            Message
                        </Button>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}