import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Switch, Route } from 'react-router-dom';
import Pass from '../../pages/Pass/Pass';
import Login from '../../pages/Login/Login';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                PushNetwork
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function NonUser() {
    const classes = useStyles();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [hasErrors, setErrors] = useState(false)

    const handleUsername = event => setUsername(event.target.value)
    const handlePassword = event => setPassword(event.target.value)

    const submitLogin = () => {
        // async function fetchData() {
        //     console.log(username, password)
        //     const loginData = {
        //         username,
        //         password
        //     }
        //     const res = await fetch("http://ec2-3-137-71-78.us-east-2.compute.amazonaws.com:3030/api/user/login", {
        //         method: 'POST',
        //         // credentials: 'include',
        //         headers: {
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify(loginData)
        //     })
        //     res
        //         .json()
        //         .then(res => console.log(res))
        //         .catch(err => { console.log(err); setErrors(err.error) })
        // }

        // fetchData()

        function setCookie(cname, cvalue) {
            console.log('Yolito')
            var d = new Date();
            d.setTime(d.getTime() + (.5 * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        setCookie('push_access_key', 'yesplease')
    }

    useEffect(() => {
        async function getCookie() {
            const res = await fetch("https://api.pushnetwork.com:3030/api/user/logout", {
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                },
                credentials: 'include'
            })
            res.json()
        }
        getCookie()
    }, [])

    return (
        <Switch>
            {/* <Route to='/confirm/:userKey' component={Pass}/> */}
            <Route component={Login} />
        </Switch>
    );
}