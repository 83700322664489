import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, ExpansionPanel, ExpansionPanelSummary, TextField, Typography, ExpansionPanelDetails, FormControl, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    root: {
        width: '100%',
    },
    heading: {
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    button: {
        marginTop: '20px'
    },
    view: {
        height: '70vh',
    }
};

const useStyles = makeStyles(styles);

export default function Settings() {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const [password, setPassword] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')
    const [passwordValidation, setPasswordValidation] = useState('')

    const [verifyPasswordError, setVerifyPasswordError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const handleCurrentPassword = event => setCurrentPassword(event.target.value)
    const handlePassword = event => setPassword(event.target.value)
    const handlePasswordValidation = event => setPasswordValidation(event.target.value)

    const validatePasswordValidation = () => {
        setVerifyPasswordError(password === passwordValidation ? '' : 'Passwords do not match')
    }

    const changePassword = () => {
        const data = {
            current_password: currentPassword,
            new_password: password
        }

        async function postData(data) {
            const res = await fetch("https://api.pushnetwork.com:3030/api/auth/confirm", {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            res
                .json()
                .then(res => {
                    console.log(res)
                    // setUserError(res.userExists ? 'User already exists' : '')`
                    if (res.error) {
                        setPasswordError(res.error)
                    } else {
                        if (res.success) setPasswordError('Password updated successfully')
                    }
                })
                .catch(err => setPasswordError(err.error))
        }
        postData(data)
    }

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
                <Card className={classes.view}>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Settings</h4>
                        <p className={classes.cardCategoryWhite}>
                            Update Your Settings
                        </p>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.root}>
                            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading}>Change Password</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <FormControl fullWidth>
                                        <TextField
                                            margin="dense"
                                            id="password"
                                            label="Current Password"
                                            type="password"
                                            value={currentPassword}
                                            onChange={handleCurrentPassword}
                                        />
                                        {passwordError}
                                        <br/>
                                        <br/>
                                        <TextField
                                            margin="dense"
                                            id="password"
                                            label="New Password"
                                            type="password"
                                            value={password}
                                            onChange={handlePassword}
                                        />
                                        <TextField
                                            margin="dense"
                                            id="confirm-pass"
                                            label="Confirm New Password"
                                            type="password"
                                            value={passwordValidation}
                                            onChange={handlePasswordValidation}
                                            onBlur={validatePasswordValidation}
                                        />
                                        {verifyPasswordError}
                                        <Button 
                                            disabled={
                                                !passwordValidation ||
                                                !password ||
                                                !currentPassword ||
                                                passwordValidation !== password
                                            }
                                            className={classes.button}
                                            variant="contained" 
                                            color="primary"
                                            onClick={changePassword}
                                        >
                                            Change Password
                                        </Button>
                                    </FormControl>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography className={classes.heading}>Users</Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        You are currently not an owner
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                                        diam eros in elit. Pellentesque convallis laoreet laoreet.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className={classes.heading}>Advanced settings</Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        Filtering has been entirely disabled for whole web server
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros,
                                        vitae egestas augue. Duis vel est augue.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Typography className={classes.heading}>Personal data</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros,
                                        vitae egestas augue. Duis vel est augue.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}