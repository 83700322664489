/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
// core components

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
};
const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto"
};
const primaryColor = ["#9c27b0", "#ab47bc", "#8e24aa", "#af2cc5"];
const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#b4b4b4",
  "#555555",
  "#333",
  "#a9afbb",
  "#eee",
  "#e7e7e7"
];

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "10px"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    fontSize: "12px",
    float: "right!important"
  },
  footer: {
    height: "6vh",
    bottom: "0",
    borderTop: "1px solid " + grayColor[11],
    ...defaultFont,
    position: "fixed",
    backgroundColor: "rgb(238, 238, 238)",
    width: "calc(100% - 260px)",
  },
  container,
  footText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  }
};

const useStyles = makeStyles(footerStyle);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container + " " + classes.footText}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link to="/home" className={classes.block}>
                Home
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a target="_blank" href="http://www.pushpros.com" className={classes.block}>
                Company
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a target="_blank" href="https://pushpros.com/deck/deck.pdf" className={classes.block}>
                Deck
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a target="_blank" href="https://pushpros.com/extension/pushpros-extension.pdf" className={classes.block}>
                Extension
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a target="_blank" href="https://pushpros.com/selfmanage/pushpros-self-managed.pdf" className={classes.block}>
                Self Manage
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a target="_blank" href="https://pushpros.com/fullymanage/pushpros-fully-managed.pdf" className={classes.block}>
                Fully Manage
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a
              href="https://pushpros.com"
              target="_blank"
              className={classes.a}
            >
              PushPROS
            </a>
            , we love to push it
          </span>
        </p>
      </div>
    </footer>
  );
}
