import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "../CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { MdGroupAdd, MdAccountBalance, MdHome, MdAccessible, MdAccessAlarm } from "react-icons/md";
import { Link } from 'react-router-dom'

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button>
          <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          </Backdrop>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks handleToggle={handleToggle} />
        </Hidden>
      </Toolbar>
      {
        open ?
          <div className={classes.backdropData} onClick={handleToggle}>
            <div className={classes.backdropList}>
              <Link to="/" className={classes.backdropListLink}>
                <MdHome />
              </Link>
              <Link to="/" className={classes.backdropListLink}>
                <MdGroupAdd />
              </Link>
              <Link to="/" className={classes.backdropListLink}>
                <MdAccessible />
              </Link>
              <Link to="/" className={classes.backdropListLink}>
                <MdAccountBalance />
              </Link>
              <Link to="/" className={classes.backdropListLink}>
                <MdAccessAlarm />
              </Link>
            </div>
          </div>
          : ''
      }
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
