import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = {
    activeLink: {
        backgroundColor: "#00acc1 !important",
        color: "#FFF !important",
    },
    sidebarLink: {    
        color: "inherit",
        margin: "0",
        display: "block",
        padding: "0",
        position: "relative",
        textDecoration: "none",
        width: "100%",
        textAlign: "center"
    },
    sidebarLinkWrapper: {
        margin: "10px 15px 0",
        display: "flex",
        padding: "10px",
        position: "relative",
        transition: "all 300ms linear",
        fontWeight: "300",
        lineHeight: "1.5em",
        borderRadius: "3px",
        backgroundColor: "transparent",
        fontSize: "1.1em",
        textDecoration: "none",
        justifyContent: "space-between",
        color: "#AFAFAF",
        "&:hover": {
            backgroundColor: "#BBC1C1",
            color: "#FFF"
        }
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        aligntItems: "center",
        fontSize: "27px",
        position: "relative",
        // top: "2.4px",
    },
    titleContainer: {
        display: "flex",
        justifyContent: "flex-start",
        width: "50%",
        alignItems: "center",
    },
    childContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "17%",
    }
};

const useStyles = makeStyles(styles);

export default function SidebarLink(props) {
    const classes = useStyles();
    const { children, title, icon, link, current, setLink } = props;
    return (
        <Link 
            onClick={() => setLink(title)}
            to={`/${link ? link.toLowerCase() : title.toLowerCase()}`} 
            className={classes.sidebarLink}
        >
            <div className={`${classes.sidebarLinkWrapper} ` + `${title === current ? classes.activeLink : ''}`}>
                <div className={classes.iconContainer}>
                    {icon}
                </div>
                <div className={classes.titleContainer}>
                    {title}
                </div>
                {
                    children && children.length
                        ?
                        <div className={classes.childContainer}>
                        </div>
                        :
                        <div className={classes.childContainer}>
                        </div>
                }
            </div>
        </Link>
    );
}

