import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor
} from "../../../../assets/jss/material-dashboard-react.js";

const headerStyle = () => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[7],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  container: {
    ...container,
    minHeight: "50px"
  },
  flex: {
    flex: 1
  },
  backdrop: {
    zIndex: 91,
    color: 'white',
  },
  backdropData: {
    zIndex: 92,
    backgroundColor: 'transparent',
    position: 'fixed',
    margin: '0 auto',
    width: '80vw',
    marginLeft: '10vw',
    height: '100vh',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: "white"
  },
  backdropList: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    width: "80vh",
  },
  backdropListLink: {
    display: "block",
    width: "55px",
    height: "55px",
    backgroundColor: "transparent",
    lineHeight: "60px",
    textAlign: "center",
    margin: "0 10px",
    borderRadius: "15px",
    boxShadow: "-2px -2px 3px rgba(255,255,255,0.05),3px 3px 3px rgba(0,0,0,0.2)",
    fontSize: "25px",
    color: "#555",
    transition: "all 150ms ease 0s",
    "&:hover": {
      color: "#00F3FF",
      textShadow: "0 0 15px #00F3FF"
    }
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  appResponsive: {
    top: "8px"
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  }
});

export default headerStyle;
