import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import UserTable from '../../components/UserTable/UserTable';
import { Input, Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import CardFooter from '../../components/Card/CardFooter';

import CreateUser from '../../components/CreateUser/CreateUser';
import { MdGroupAdd } from 'react-icons/md';

const styles = {
    // userDialog: {
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    // },
    userForm: {
        width: "60vw",
        margin: "50px auto",
        color: "blue",
        padding: "10px"
    },
    height: {
        height: "75vh",
    },
    users: {
        overflow: "scroll"
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    tableUpgradeWrapper: {
        display: "block",
        width: "100%",
        overflowX: "auto",
        WebkitOverflowScrolling: "touch",
        MsOverflowStyle: "-ms-autohiding-scrollbar"
    },
    table: {
        width: "100%",
        maxWidth: "100%",
        marginBottom: "1rem",
        backgroundColor: "transparent",
        borderCollapse: "collapse",
        display: "table",
        borderSpacing: "2px",
        borderColor: "grey",
        "& thdead tr th": {
            fontSize: "1.063rem",
            padding: "12px 8px",
            verticalAlign: "middle",
            fontWeight: "300",
            borderTopWidth: "0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
            textAlign: "inherit"
        },
        "& tbody tr td": {
            padding: "12px 8px",
            verticalAlign: "middle",
            borderTop: "1px solid rgba(0, 0, 0, 0.06)"
        },
        "& td, & th": {
            display: "table-cell"
        }
    },
    center: {
        textAlign: "center"
    }
};

const useStyles = makeStyles(styles);


const Transition = React.forwardRef(function Transition(props, ref) {
    // return <Slide direction="up" ref={ref} {...props} />
})

export default function Test() {
    const classes = useStyles();
    const [users, setUsers] = useState([{}])
    const [search, setSearch] = useState('')
    const [searchUsers, setSearchUsers] = useState([{}])
    const [modalActive, setModalActive] = useState(false)
    const [hasErrors, setErrors] = useState(false)

    useEffect(() => {
        async function fetchData() {
            // TODO change this post request to get request that passes in the client id
            const res = await fetch("https://api.pushnetwork.com:3030/api/user/users", {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ user_id: 1 })
            })
            res
                .json()
                .then(res => {
                    const roleKey = {
                        1: 'Admin',
                        2: 'Production',
                        3: 'Sales',
                        4: 'Client'
                    }

                    res.map(user => {
                        user.role = roleKey[`${user.role}`]
                        user.confirmed = user.confirmed ? 'Yes' : 'No'
                        return user
                    })
                    setUsers(res)
                })
                .catch(err => { console.log('my error:', err); setErrors(JSON.stringify(err)) })
        }

        fetchData()
    }, [setUsers])

    const handleClickOpen = () => setModalActive(true)
    const handleClose = () => setModalActive(false)
    const handleSearch = event => {
        const searchUsers = users.filter(user =>
            user.username.toLowerCase().includes(event.target.value)
            || user.email.toLowerCase().includes(event.target.value)
        )
        setSearchUsers(searchUsers)
        setSearch(event.target.value)
    }
    // const handleSelect = event => setRole(event.target.value)
    const createUser = (user) => {
        async function fetchData(user) {
            const newUser = {
                email: user.email,
                password: user.password,
                username: user.username
            }
            const res = await fetch("https://api.pushnetwork.com:3030/api/user/register", {
                method: 'POST',
                body: JSON.stringify(newUser)
            })
            res.json()
                .then(res => {
                    console.log(res)
                    return res
                })
                .then(res => setUsers(res.data))
                .catch(err => setErrors(err))
        }
        fetchData(user)
    }



    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
                <Card className={classes.height}>
                    {hasErrors}
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>User List</h4>
                        <p className={classes.cardCategoryWhite}>Create, Read, Update, Delete Users as Admin</p>
                    </CardHeader>
                    <CardBody className={classes.users}>
                        <Input fullWidth placeholder="Search for Username or Email" value={search} onChange={handleSearch} />
                        <UserTable
                            tableHeaderColor="primary"
                            tableHead={Object.keys(users[0]).map(word => word.toUpperCase())}
                            tableData={search ? searchUsers.map(user => Object.values(user)) : users.map(user => Object.values(user))}
                        />
                    </CardBody>
                    <CardFooter>
                        <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
                            <MdGroupAdd fontSize="32px" />
                        </Fab>
                    </CardFooter>
                    <CreateUser modalActive={modalActive} handleClose={handleClose} createUser={createUser} />
                </Card>
            </GridItem>
        </GridContainer>
    )
}

