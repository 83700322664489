import React from 'react'
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

const styles = {
    view: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    image: {
        width: "300px"
    }
}

const useStyles = makeStyles(styles);

export default function Lost() {
    const classes = useStyles()
    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10} className={classes.view}>
                <p>
                    You're lost my friend, please come <Link to="/">home</Link>
                </p>
                <img className={classes.image} src="https://images.unsplash.com/photo-1518626981033-f00082ce6f8b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=694&q=80" alt="push it back home"/>
            </GridItem>
        </GridContainer>
    )
}