import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import { MdTimeline, MdChildCare } from 'react-icons/md';
import Chart from '../../components/Chart/Chart';
import {
    Legend, LineChart, Line,
    CartesianGrid, XAxis, YAxis,
    Tooltip, RadialBar, RadialBarChart,
    RadarChart, PolarGrid, PolarAngleAxis,
    PolarRadiusAxis, Radar, Sankey, ResponsiveContainer
} from 'recharts';
import CardBody from '../../components/Card/CardBody';
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Tabs, Tab } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function Performance() {
    const classes = useStyles()
    const [tab, setTab] = useState(1)

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="success">
                        <GridItem xs={12} sm={12} md={11}>
                            <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
                                <LineChart data={data[tab]}
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="pv" stroke="#1d12ff" />
                                    <Line type="monotone" dataKey="uv" stroke="#d2ff70" />
                                </LineChart>
                            </ResponsiveContainer>
                            <Tabs value={tab} onChange={handleChange} aria-label="graph tabs">
                                <Tab label="1 Day" />
                                <Tab label="5 Days" />
                                <Tab label="10 Days" />
                                <Tab label="1 Month" />
                                <Tab label="6 Months" />
                                <Tab label="1 Year" />
                            </Tabs>
                        </GridItem>
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Click Performance</h4>
                        <p className={classes.cardCategory}>
                            Total clicks
                    </p>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader color="rose">
                        <ResponsiveContainer width="100%" aspect={4.0 / 2.5}>
                            <RadialBarChart
                                innerRadius="10%"
                                outerRadius="80%"
                                data={data2}
                                startAngle={180}
                                endAngle={0}
                            >
                                <RadialBar minAngle={15} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey='uv' />
                                <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="LEFT" />
                                <Tooltip />
                            </RadialBarChart>
                        </ResponsiveContainer>
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Push Demographics <MdChildCare /></h4>
                        <p className={classes.cardCategory}>
                            Age of Audience
                        </p>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader color="primary">
                        <ResponsiveContainer width="100%" aspect={4.0 / 2.5}>
                            <RadarChart outerRadius={90} data={data3}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="subject" />
                                <PolarRadiusAxis angle={30} domain={[0, 150]} />
                                <Radar name="Site A" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                                <Radar name="Site B" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                                <Legend />
                            </RadarChart>
                        </ResponsiveContainer>
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Verticals</h4>
                        <p className={classes.cardCategory}>% Spent</p>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader color="info">
                        <ResponsiveContainer width="100%" aspect={4.0 / 2.5}>
                            <Sankey
                                data={data0}
                                node={{ fill: '#8a52b6' }}
                                link={{ stroke: '#2ba12d' }}
                            >
                                <Tooltip />
                            </Sankey>
                        </ResponsiveContainer>
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Conversions</h4>
                        <p className={classes.cardCategory}>How many follow through</p>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

const data = [
    [{
        "name": "3/26",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "3/27",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "3/28",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },]
    , [{
        "name": "3/26",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "3/27",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "3/28",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "3/29",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "3/30",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    }],
    [{
        "name": "3/21",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "3/22",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "3/23",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "3/24",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "3/25",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    }, {
        "name": "3/26",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "3/27",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "3/28",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "3/29",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "3/30",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    }],
    [{
        "name": "3/26",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "3/4",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "3/6",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "3/8",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "3/10",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    }, {
        "name": "3/12",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "3/14",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "3/16",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "3/18",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "3/20",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    }, {
        "name": "3/22",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "3/24",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "3/26",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "3/28",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "3/30",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    }],
    [{
        "name": "Nov",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "Dec",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "Jan",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "Feb",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "Mar",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    }],
    [
        {
            "name": "Jun 2019",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Jul 2019",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Aug 2019",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Sep 2019",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Oct 2019",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Nov 2019",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Dec 2019",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
        },
        {
            "name": "Jan 2020",
            "uv": 2000,
            "pv": 9800,
            "amt": 2290
        },
        {
            "name": "Feb 2020",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
        },
        {
            "name": "Mar 2020",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
        }]
]

const data2 = [
    {
        "name": "18-24",
        "uv": 31.47,
        "pv": 2400,
        "fill": "#8884d8"
    },
    {
        "name": "25-29",
        "uv": 26.69,
        "pv": 4567,
        "fill": "#83a6ed"
    },
    {
        "name": "30-34",
        "uv": -15.69,
        "pv": 1398,
        "fill": "#8dd1e1"
    },
    {
        "name": "35-39",
        "uv": 8.22,
        "pv": 9800,
        "fill": "#82ca9d"
    },
    {
        "name": "40-49",
        "uv": -8.63,
        "pv": 3908,
        "fill": "#a4de6c"
    },
    {
        "name": "50+",
        "uv": -2.63,
        "pv": 4800,
        "fill": "#d0ed57"
    },
    {
        "name": "unknown",
        "uv": 6.67,
        "pv": 4800,
        "fill": "#ffc658"
    }
]

const data3 = [
    {
        "subject": "Insurance",
        "A": 120,
        "B": 110,
        "fullMark": 150
    },
    {
        "subject": "Eduation",
        "A": 98,
        "B": 130,
        "fullMark": 150
    },
    {
        "subject": "Finance",
        "A": 86,
        "B": 130,
        "fullMark": 150
    },
    {
        "subject": "Telecommunications",
        "A": 99,
        "B": 100,
        "fullMark": 150
    },
    {
        "subject": "Hospitality",
        "A": 85,
        "B": 90,
        "fullMark": 150
    },
    {
        "subject": "Ecommerce",
        "A": 65,
        "B": 85,
        "fullMark": 150
    }
]
const data0 = {
    "nodes": [
        {
            "name": "Visit"
        },
        {
            "name": "Share with Friends"
        },
        {
            "name": "Page-Click"
        },
        {
            "name": "Subscribe with Email"
        },
        {
            "name": "Lost"
        }
    ],
    "links": [
        {
            "source": 0,
            "target": 1,
            "value": 3728
        },
        {
            "source": 0,
            "target": 2,
            "value": 354170
        },
        {
            "source": 2,
            "target": 3,
            "value": 62429
        },
        {
            "source": 2,
            "target": 4,
            "value": 291741
        }
    ]
};