import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import Chart from '../../components/Chart/Chart';
import Danger from "../../components/Typography/Danger.js";
import { Md3DRotation, MdPermContactCalendar, MdAccountBalanceWallet } from 'react-icons/md'
import Warning from "@material-ui/icons/Warning";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";


// const styles = {
//     view: {
//         overflow: "scroll"
//     },
//     stats: {
//         color: 'gray',
//         display: "inline-flex",
//         fontSize: "12px",
//         lineHeight: "22px",
//         "& svg": {
//             top: "4px",
//             width: "16px",
//             height: "16px",
//             position: "relative",
//             marginRight: "3px",
//             marginLeft: "3px"
//         },
//         "& .fab,& .fas,& .far,& .fal,& .material-icons": {
//             top: "4px",
//             fontSize: "16px",
//             position: "relative",
//             marginRight: "3px",
//             marginLeft: "3px"
//         }
//     },
// }

const useStyles = makeStyles(styles);

export default function Home() {
    const classes = useStyles()
    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                            <Md3DRotation />
                        </CardIcon>
                        <p className={classes.cardCategory}>Used Space</p>
                        <h3 className={classes.cardTitle}>
                            49/50 <small>GB</small>
                        </h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <Danger>
                                <Warning />
                            </Danger>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                Get more space
                            </a>
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                            <MdAccountBalanceWallet />
                        </CardIcon>
                        <p className={classes.cardCategory}>Revenue</p>
                        <h3 className={classes.cardTitle}>$34,245</h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <MdPermContactCalendar />
                            Last 24 Hours
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                            <Md3DRotation />
                        </CardIcon>
                        <p className={classes.cardCategory}>Used Space</p>
                        <h3 className={classes.cardTitle}>
                            49/50 <small>GB</small>
                        </h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <Danger>
                                <Warning />
                            </Danger>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                Get more space
                            </a>
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                            <MdAccountBalanceWallet />
                        </CardIcon>
                        <p className={classes.cardCategory}>Revenue</p>
                        <h3 className={classes.cardTitle}>$34,245</h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <MdPermContactCalendar />
                            Last 24 Hours
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <Chart />
        </GridContainer>
    )
}