import React, { useEffect } from 'react'
import { Switch, Route, Redirect, Link } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../../components/Navbars/Navbar.js";
import Footer from "../../components/Footer/Footer.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";

import bgImage from "../../assets/img/sidebar-2.jpg";
import logo from "../../assets/img/reactlogo.png"; import {
    drawerWidth,
    transition,
    container
} from "../../assets/jss/material-dashboard-react.js";
import Users from '../../pages/Users/Users.js';
import Settings from "../../pages/Settings/Settings.js"
import Lost from '../../pages/Lost/Lost.js';
import Home from '../../pages/Home/Home.js';
import Performance from '../../pages/Performance/Performance'
import Profile from '../../pages/Profile/Profile.js';
import Logout from '../../pages/Logout/Logout'

const appStyle = theme => ({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh"
    },
    mainPanel: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch",
        background: "#eee",
        overflowX: "hidden"
    },
    navbar: {
        height: "75px",
        position: "relative !important"
    },
    content: {
        marginTop: "70px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 123px)"
    },
    container,
    map: {
        marginTop: "70px"
    },
    view: {
        height: "calc(100vh - 75px - 6vh)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
});

let ps;

// const switchRoutes = (
//     <Switch>
//         {routes.map((prop, key) => {
//             if (prop.layout === "/admin") {
//                 return (
//                     <Route
//                         path={prop.layout + prop.path}
//                         component={prop.component}
//                         key={key}
//                     />
//                 );
//             }
//             return null;
//         })}
//         <Redirect from="/admin" to="/admin/dashboard" />
//     </Switch>
// );

const useStyles = makeStyles(appStyle);

export default function User({ ...rest }) {
    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [color, setColor] = React.useState("blue");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [current, setCurrent] = React.useState("Home")
    const [view, setView] = React.useState("client")

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    const setLink = (title) => {
        setCurrent(title)
    }
    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);
    return (
        <div className={classes.wrappemr}>
            <Sidebar
                routes={[]}
                logoText={"Push Network"}
                logo={logo}
                image={bgImage}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                current={current}
                setLink={setLink}
                view={view}
                {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
                <div className={classes.navbar}>
                    <Navbar
                        routes={[]}
                        handleDrawerToggle={handleDrawerToggle}
                    />
                </div>
                <div className={classes.view}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/home" component={Home} />
                        <Route path="/users" component={Users} />
                        <Route path="/performance" component={Performance} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/user/:user" component={Profile} />
                        <Route path="/my-profile" render={() => <Profile view='my-profile' />} />
                        <Route component={Lost} />
                    </Switch>
                </div>
                <Footer />
            </div>
        </div>
    );
}
