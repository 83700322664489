// Might want to move this server side based on user type
import React from 'react'
import {
    MdFace,
    MdHome,
    MdTimeline
}

    from "react-icons/md"

const links = [
    {
        title: 'Home',
        icon: <MdHome />,
        children: [],
        link: '',
    },
    {
        title: 'Users',
        icon: <MdFace />,
        children: []
    },
    {
        title: 'Performance',
        icon: <MdTimeline />,
        children: []
    }
]

export default links