import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom';

export default function Logout() {
    useEffect(() => {
        function setCookie(cname, cvalue, ) {
            var d = new Date();
            d.setTime(d.getTime() + (.5 * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
        setCookie('user', 'yolo')
    }, [])
    return (
        <Redirect to='/'></Redirect>
    )
}