import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, DialogTitle, DialogContent, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
const styles = {
    userForm: {
        width: "60vw",
        margin: "50px auto",
        color: "blue",
        padding: "10px"
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    tableUpgradeWrapper: {
        display: "block",
        width: "100%",
        overflowX: "auto",
        WebkitOverflowScrolling: "touch",
        MsOverflowStyle: "-ms-autohiding-scrollbar"
    },
    table: {
        width: "100%",
        maxWidth: "100%",
        marginBottom: "1rem",
        backgroundColor: "transparent",
        borderCollapse: "collapse",
        display: "table",
        borderSpacing: "2px",
        borderColor: "grey",
        "& thdead tr th": {
            fontSize: "1.063rem",
            padding: "12px 8px",
            verticalAlign: "middle",
            fontWeight: "300",
            borderTopWidth: "0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
            textAlign: "inherit"
        },
        "& tbody tr td": {
            padding: "12px 8px",
            verticalAlign: "middle",
            borderTop: "1px solid rgba(0, 0, 0, 0.06)"
        },
        "& td, & th": {
            display: "table-cell"
        }
    },
    center: {
        textAlign: "center"
    }
};

const useStyles = makeStyles(styles);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function CreateUser(props) {
    const classes = useStyles();

    const [role, setRole] = useState(2)
    const [username, setUsername] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    // const [password, setPassword] = useState('')
    // const [passwordValidation, setPasswordValidation] = useState('')

    // const [verifyPasswordError, setVerifyPasswordError] = useState('')
    const [emailError, setEmailError] = useState('')
    // const [passwordError, setPasswordError] = useState(false)
    const [userError, setUserError] = useState('')
    const [hasErrors, setErrors] = useState(false)

    const [validForm, setValidForm] = useState(false)

    const handleSelect = event => setRole(event.target.value)
    const handleEmail = event => setEmail(event.target.value)
    const handleUsername = event => setUsername(event.target.value)
    const handleFirstname = event => setFirstname(event.target.value)
    const handleLastname = event => setLastname(event.target.value)
    const handleCompany = event => setCompany(event.target.value)
    // const handlePassword = event => setPassword(event.target.value)
    // const handlePasswordValidation = event => setPasswordValidation(event.target.value)

    const validateEmail = () => {
        const validEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        setEmailError(validEmail ? '' : 'Please enter a valid email')
    }
    // const validatePasswordValidation = () => {
    //     setVerifyPasswordError(password === passwordValidation ? '' : 'Passwords do not match')
    // }
    const validateUsername = () => {
        if (username.length < 4 || username.length > 40) {
            return setUserError('Username must be between 4 and 40 characters')
        }
        async function fetchData() {
            const res = await fetch("https://api.pushnetwork.com:3030/api/user/check", {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ username })
            })
            res
                .json()
                .then(res => {
                    setUserError(res.userExists ? 'User already exists' : '')
                })
                .catch(err => setErrors(err))
        }

        fetchData()
    }
    const validateForm = () => {
        console.log(!emailError && !userError)
        return !emailError && !userError
    }

    const postUser = () => {
        const user = {
            username,
            email,
            // password,
            role,
            first_name: firstname,
            last_name: lastname,
            company
        }
        console.log(user)
        async function postData(user) {
            const res = await fetch("https://api.pushnetwork.com:3030/api/user/register", {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(user)
            })
            res
                .json()
                .then(res => {
                    setUserError(res.userExists ? 'User already exists' : '')
                })
                .catch(err => setErrors(err))
        }
        postData(user)
    }

    return (
        <Dialog className={classes.userDialog} fullScreen TransitionComponent={Transition} open={props.modalActive} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle>Add a New User</DialogTitle>
            <FormControl className={classes.userForm} margin="normal">
                <InputLabel id="user-type-label">User Role</InputLabel>
                <Select
                    id="user-type"
                    label="user type"
                    value={role}
                    onChange={handleSelect}
                >
                    <MenuItem value={2}>Production</MenuItem>
                    <MenuItem value={3}>Sales</MenuItem>
                    <MenuItem value={4}>Client</MenuItem>
                </Select>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={handleEmail}
                    onBlur={validateEmail}
                />
                {emailError}
                <TextField
                    margin="dense"
                    id="username"
                    label="Username"
                    type="text"
                    value={username}
                    onChange={handleUsername}
                    onBlur={validateUsername}
                />
                {userError}
                <TextField
                    margin="dense"
                    id="firstname"
                    label="First Name"
                    type="text"
                    value={firstname}
                    onChange={handleFirstname}
                />
                <TextField
                    margin="dense"
                    id="lastname"
                    label="Last Name"
                    type="text"
                    value={lastname}
                    onChange={handleLastname}
                />
                <TextField
                    margin="dense"
                    id="company"
                    label="Company"
                    type="text"
                    value={company}
                    onChange={handleCompany}
                />
                {/* <TextField
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={handlePassword}
                />
                <TextField
                    margin="dense"
                    id="confirm-pass"
                    label="Confirm Password"
                    type="password"
                    value={passwordValidation}
                    onChange={handlePasswordValidation}
                    onBlur={validatePasswordValidation}
                /> */}
                {/* {verifyPasswordError} */}
                <DialogActions>
                    <Button onClick={props.handleClose} color="secondary">Cancel</Button>
                    <Button
                        disabled={!username.length
                            || !email.length
                            || emailError
                            || userError
                        }
                        onClick={postUser}
                        color="primary"
                    >
                        Create
                    </Button>
                </DialogActions>
            </FormControl>
        </Dialog>
    )
}