import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { hexToRgb, grayColor } from '../../assets/jss/material-dashboard-react'
import { Link } from 'react-router-dom'
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core'
import { MdExpandMore, MdSettings, MdPermIdentity } from 'react-icons/md'
import SidebarLink from './SidebarLink'

const styles = {
    profile: {
        backgroundColor: "transparent",
        paddingBottom: "5px",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",

            height: "1px",
            right: "15px",
            width: "calc(100% - 30px)",
            backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)"
        }
    },
    profileContainer: {
        paddingTop: "5px",
    },
    avatar: {
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        objectFit: "cover"
    },
    user: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFF",
    },
    name: {
        paddingLeft: "20px",
        fontSize: "20px"
    },
    links: {
        display: "flex",
        flexDirection: "column",
        width: '100%'
    }
}

const useStyles = makeStyles(styles)

export default function SidebarProfile(props) {
    const classes = useStyles()
    let { user } = props
    let sampleUser = {
        name: "Mike Bubly",
        img: "https://cdn.pixabay.com/photo/2015/03/03/20/42/man-657869_1280.jpg",
        children: [
            {
                title: 'Admin Settings',
                link: '/admin/jadfkh9438r9'
            }
        ]
    }
    return (
        <div className={classes.profileContainer}>
            <ExpansionPanel className={classes.profile}>
                <ExpansionPanelSummary expandIcon={<MdExpandMore color="#FFF" />}>
                    <div className={classes.user}>
                        <img src={sampleUser.img} alt='avatar' className={classes.avatar} />
                        <Typography className={classes.name}>
                            {sampleUser.name}
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className={classes.links}>
                        <SidebarLink
                            title={'My Profile'}
                            icon={<MdPermIdentity />}
                            children={[]}
                            setLink={() => { }}
                            current={''}
                            link='my-profile'
                        />
                        <SidebarLink
                            title={'Settings'}
                            icon={<MdSettings />}
                            children={[]}
                            setLink={() => { }}
                            current={''}
                        />
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}