/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "../Navbars/RTLNavbarLinks.js";
import links from './links'

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import SidebarLink from "./SidebarLink.js";
import SidebarProfile from "./SidebarProfile.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, current, setLink } = props;
  const sidebarLinks = links.map(link =>
    <SidebarLink
      setLink={setLink}
      title={link.title}
      icon={link.icon}
      children={link.children}
      link={link.link}
      current={current}
      key={link.title} />
  )
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://pushpros.com"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <SidebarProfile />
            {sidebarLinks}
          </div>
          <div
            className={classes.background}
            style={{ backgroundImage: "url(https://scontent.fagc1-1.fna.fbcdn.net/v/t1.0-9/12963535_1023564834389559_8009979815236579574_n.jpg?_nc_cat=102&_nc_sid=8bfeb9&_nc_ohc=q-3MJ8nJ6NoAX9PN3cQ&_nc_ht=scontent.fagc1-1.fna&oh=844872c1d26ff9abb2d6f5458203c4b6&oe=5EA3F4BA)" }}
          />
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
