import React, { useState, useEffect } from 'react'
import ChartistGraph from "react-chartist";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import { AccessTime } from '@material-ui/icons';
import CardFooter from '../../components/Card/CardFooter';
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

var Chartist = require("chartist");
var delays = 80,
    durations = 500;

const useStyles = makeStyles(styles);

export default function Chart(props) {
    const classes = useStyles();
    const [graph, setGraph] = useState([{}])
    const { title, desc, } = props

    // useEffect(() => {
    //     async function fetchData() {
    //         const res = await fetch("http://3.137.71.78:3030//data")
    //         res
    //             .json()
    //             .then(res => setUsers(res.data))
    //             .catch(err => setErrors(err))
    //     }

    //     fetchData()
    // }, [setUsers])

    var data = {
        labels: ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8', 'W9', 'W10'],
        series: [
            [1, 2, 4, 8, 6, -2, -1, -4, -6, -2]
        ]
    };

    var options = {
        high: 10,
        low: -10,
        axisX: {
            labelInterpolationFnc: function (value, index) {
                return index % 2 === 0 ? value : null;
            }
        }
    };

    var type = 'Bar';
    var aspectRatio = 'ct-octave';

    return (
        <Card>
            <CardHeader color="success">
                <div>
                    <ChartistGraph className={aspectRatio} data={data} options={options} type={type} />
                </div>
            </CardHeader>
            <CardBody>
                <h4 className={classes.cardTitle}>{title}</h4>
                <p className={classes.cardCategory}>
                    {desc}
                </p>
            </CardBody>
            <CardFooter chart>
                <div className={classes.stats}>
                    <AccessTime /> updated 4 minutes ago
                        </div>
            </CardFooter>
        </Card>
    )
}

function createLines(n) {
    let arr = []

    for (let i = 0; i < n; i++) {
        let nums = []
        let range = 500

        for (let j = 0; j < 7; j++) {
            nums.push(Math.ceil(Math.random() * range))
        }
        arr.push(nums)
    }
    return arr
}

const dailySalesChart = {
    data: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [[12, 17, 7, 17, 23, 18, 38]]
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        high: 50, //yolo we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    // for animation
    animation: {
        draw: function (data) {
            if (data.type === "line" || data.type === "area") {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint
                    }
                });
            } else if (data.type === "point") {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    }
};
